import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import App from './App';
import config from './config';

import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

export default createBrowserHistory({});

ReactGA.initialize(config.ga_analytics);

const hist = createBrowserHistory();
hist.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
});

ReactDOM.render(
  <Router history={hist}>
    <App />
  </Router>,
  document.getElementById("root")
);
