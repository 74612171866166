import React, { Component } from "react";
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import config from './data/ServicesData';

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImageIndexes: {} // Para controlar qué imagen se muestra de cada servicio
    };
  }

  // Método para cambiar a la siguiente imagen
  nextImage = (serviceIndex) => {
    const { activeImageIndexes } = this.state;
    const service = config.services[serviceIndex];
    
    // Verificar si el servicio tiene imágenes múltiples
    if (!service.images || service.images.length <= 1) return;
    
    const currentIndex = activeImageIndexes[serviceIndex] || 0;
    const nextIndex = (currentIndex + 1) % service.images.length;
    
    this.setState({
      activeImageIndexes: {
        ...activeImageIndexes,
        [serviceIndex]: nextIndex
      }
    });
  }

  // Método para cambiar a la imagen anterior
  prevImage = (serviceIndex) => {
    const { activeImageIndexes } = this.state;
    const service = config.services[serviceIndex];
    
    // Verificar si el servicio tiene imágenes múltiples
    if (!service.images || service.images.length <= 1) return;
    
    const currentIndex = activeImageIndexes[serviceIndex] || 0;
    const prevIndex = (currentIndex - 1 + service.images.length) % service.images.length;
    
    this.setState({
      activeImageIndexes: {
        ...activeImageIndexes,
        [serviceIndex]: prevIndex
      }
    });
  }

  render() {
    const { activeImageIndexes } = this.state;
    
    return (
      <section id="services" className="services-section-6">
        <div className="event-schedule-area-two bg-color pad100">
          <div className="container">
            <div className="row mb-5">
              <div className="title col-md-8 col-xl-6 text-center mx-auto">
                <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={100}>
                  <h2 className="font-weight-bold mb-4">{config.title}</h2>
                  <p className="mb-2">{config.subtitle}</p>
                  <p>{config.description}</p>
                </ScrollAnimation>
              </div>
            </div>
            
            <div className="row">
              {config.services.map((service, index) => {
                // Determinar qué imágenes mostrar
                const images = service.images || [];
                const currentImageIndex = activeImageIndexes[index] || 0;
                const hasMultipleImages = images.length > 1;
                
                return (
                  <div key={index} className="col-md-6 col-lg-4 mb-4">
                    <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={service.delay}>
                      <div className="card h-100 shadow-sm">
                        {/* Contenedor de imagen con controles si hay múltiples imágenes */}
                        <div className="position-relative">
                          {images.length > 0 ? (
                            <img 
                              className="card-img-top" 
                              src={images[currentImageIndex]} 
                              alt={service.title}
                              style={{ height: '220px', objectFit: 'cover' }}
                            />
                          ) : (
                            <div className="bg-light text-center p-5" style={{ height: '220px' }}>
                              <i className="fa fa-image fa-3x mb-2 text-muted"></i>
                              <p className="text-muted">Sin imagen</p>
                            </div>
                          )}
                          
                          {/* Controles de navegación para imágenes múltiples */}
                          {hasMultipleImages && (
                            <>
                              <button 
                                className="position-absolute btn btn-sm btn-dark opacity-75" 
                                style={{ left: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.prevImage(index);
                                }}
                              >
                                <i className="fa fa-chevron-left"></i>
                              </button>
                              
                              <button 
                                className="position-absolute btn btn-sm btn-dark opacity-75" 
                                style={{ right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.nextImage(index);
                                }}
                              >
                                <i className="fa fa-chevron-right"></i>
                              </button>
                              
                              {/* Indicador de imagen actual */}
                              <div className="position-absolute py-1 px-2 bg-dark text-white rounded-pill opacity-75" 
                                   style={{ bottom: '10px', right: '10px', fontSize: '0.8rem' }}>
                                {currentImageIndex + 1}/{images.length}
                              </div>
                            </>
                          )}
                        </div>
                        
                        <div className="card-body">
                          <h4 className="card-title">{service.title}</h4>
                          <p className="card-text">{service.description}</p>
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;