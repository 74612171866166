import React from 'react';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isNavOpen: false
		};
		this.toggleNav = this.toggleNav.bind(this);
	}

	toggleNav() {
		this.setState({
			isNavOpen: !this.state.isNavOpen
		});
	}

	render() {
		return (
			<section className='footer-tempalte-6'>
				<footer className="text-black">
					<div className="container py-4 py-lg-5">
					<div className="row justify-content-center">
							<div className="col-sm-4 col-md-4 text-center text-lg-left d-flex flex-column">
							<h3 className="font-weight-bold">Comercial Win</h3>
								<ul className="list-unstyled">
									<li>San Francisco 7310</li>
									<li>San Ramón, Santiago</li>
									<li><a className="text-black" href="mailto:ventas@comercialwin.cl">ventas@comercialwin.cl</a></li>
								</ul>
							</div>
							<div className="col-sm-4 col-md-4 text-center text-lg-left d-flex flex-column">
							<h3 className="font-weight-bold">Enlaces Rápidos</h3>
								<ul className="list-unstyled">
									<li><a className="text-black" href="#">Inicio</a></li>
									<li><a className="text-black" href="#about">Quiénes somos</a></li>
									<li><a className="text-black" href="#services">Productos y Servicios</a></li>
								</ul>
							</div>
							<div className="col-lg-4 text-center text-lg-left d-flex flex-column align-items-center order-first align-items-lg-start order-lg-last">
								<div className="font-weight-bold d-flex align-items-center mb-2"><span className="bs-icon-rounded bs-icon-primary d-flex justify-content-center align-items-center bs-icon mr-2"><svg className="bi bi-bezier" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
									<path fillRule="evenodd" d="M0 10.5A1.5 1.5 0 0 1 1.5 9h1A1.5 1.5 0 0 1 4 10.5v1A1.5 1.5 0 0 1 2.5 13h-1A1.5 1.5 0 0 1 0 11.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10.5.5A1.5 1.5 0 0 1 13.5 9h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM6 4.5A1.5 1.5 0 0 1 7.5 3h1A1.5 1.5 0 0 1 10 4.5v1A1.5 1.5 0 0 1 8.5 7h-1A1.5 1.5 0 0 1 6 5.5v-1zM7.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"></path>
									<path d="M6 4.5H1.866a1 1 0 1 0 0 1h2.668A6.517 6.517 0 0 0 1.814 9H2.5c.123 0 .244.015.358.043a5.517 5.517 0 0 1 3.185-3.185A1.503 1.503 0 0 1 6 5.5v-1zm3.957 1.358A1.5 1.5 0 0 0 10 5.5v-1h4.134a1 1 0 1 1 0 1h-2.668a6.517 6.517 0 0 1 2.72 3.5H13.5c-.123 0-.243.015-.358.043a5.517 5.517 0 0 0-3.185-3.185z"></path>
									</svg></span><span>Comercial Win</span></div>
								<p className="text-black-50">Grupo Chaba Ingeniería y Construcciones. Entregamos soluciones industriales con los más altos estándares de calidad y compromiso.</p>
							</div>
						</div>
						<hr />
						<div className="d-flex justify-content-between pt-3" >
							<p className="text-black-50 mb-0">Sitio desarrollado por <a className="neokode" href="https://www.neokode.cl" target="_blank" rel="noopener noreferrer">Neokode</a></p>
							<p className="text-black-50 mb-0">&copy; {new Date().getFullYear()} Comercial Win. Todos los derechos reservados.</p>
						</div>
					</div>
				</footer>
			</section>
		);
	}
}
export default Footer;