const local = {
  ga_analytics: '',
  ReCAPTCHA: '',
  api: "https://api.comercialwin.cl"
};

const dev = {
  ga_analytics: '',
  ReCAPTCHA: '',
  api: "https://api.comercialwin.cl"
};

const prod = {
  ga_analytics: '',
  ReCAPTCHA: '',
  api: "https://api.comercialwin.cl"
};

const config = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'production'
  ? prod
  : process.env.REACT_APP_ENV === 'development'
  ? dev
  : local;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
