import React from 'react';
import Loader from '../components/Shared/Loader.js';
import { Preloader, Placeholder } from 'react-preloading-screen';
import Navbar from '../components/Header/Navbar';
import About from '../components/Contents/About';
import Banner from '../components/Contents/Banner';
import Footer from '../components/Footer/Footer';
import Services from '../components/Contents/Services';
import Contact from '../components/Contents/Contact';

class Home extends React.Component {
  render() {
    return (
      <div className='template6'>
        <Preloader>
          <Navbar />
          <Banner />
          <About />
          <Services />
          <Contact />
          <Footer />
          <Placeholder>
            <Loader />
          </Placeholder>
        </Preloader>
      </div>
    );
  }
}

export default Home;