import React from 'react';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import about3 from '../../assets/image/about.jpg'


class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false
    };
  }


  render() {
    return (
      <section id='about' className='about-section-6 bg-light'>
        <div className="row d-flex bg-light m-0">
          <div className="col-md-6 d-flex align-items-center">
            <div className="row justify-content-center py-5">
              <div className="col-md-10">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={100}>
              <h1 className="mb-4">Quiénes somos</h1>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={150}>
              <p className="mb-4 pb-2">Comercial Win, perteneciente al Grupo Chaba Ingeniería y Construcciones, ha basado su desarrollo y crecimiento en su agilidad, flexibilidad y compromiso en la entrega de diversas soluciones.</p>
              <p className="mb-4 pb-2">Somos una empresa con resultados demostrados en entregar Innovación a nuestros clientes, de manera de generarles un proceso limpio y sustentable en el tiempo.</p>
              <p className="mb-4 pb-2">Comercial Win está comprometido en acompañar a nuestros Clientes en toda la cadena de valor de sus operaciones, buscando la excelencia operacional, altos índices en términos de seguridad y productividad.</p>
              </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="col-md-6 " style={{ backgroundImage: `url(${about3})`, height: '450px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} >
          </div>
        </div>
      </section>
    );
  }
}

export default About;