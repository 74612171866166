const config = {
  title: "PRODUCTOS Y SERVICIOS",
  subtitle: "ADECUADOS A TODA LA CADENA DE VALOR",
  description: "ENTREGAMOS PRODUCTOS Y SERVICIOS CON LOS MÁS ALTOS ESTÁNDARES EXIGIDOS POR LA INDUSTRIA",
  services: [
    {
      title: "Elementos de Gomas Técnicas",
      description: "Fabricación y suministro de guardafangos, burletes para sellados de cabinas, goma de piso, sello de barras de perforación, faldones y manga de colector de polvo, rubber packing.",
      images: [
        require("../../../assets/image/product1-1.jpg"),
        require("../../../assets/image/product1-2.jpg"),
        require("../../../assets/image/product1-3.jpg")
      ],
      delay: 150
    },
    {
      title: "Protección Personal",
      description: "Elementos de protección personal como guantes, trajes de cuero especiales, ropa de seguridad especializada para climas y condiciones adversas.",
      images: [
        require("../../../assets/image/product2-1.jpg"),
      ],
      delay: 200
    },
    {
      title: "Aceros de Perforación",
      description: "Triconos de perforación para producción, exploración y pozos de agua, barras de perforación DDH (Diamantina), amortiguadores para perforadoras.",
      images: [
        require("../../../assets/image/product3-1.jpeg"),
        require("../../../assets/image/product3-2.jpeg"),
        require("../../../assets/image/product3-3.png")
      ],
      delay: 250
    },
    {
      title: "Ground Engaging Tools",
      description: "Elementos de desgastes, adaptadores, dientes, calzas, cuchillas para equipos de minería y construcción.",
      images: [
        require("../../../assets/image/product4-1.jpg"),
        require("../../../assets/image/product4-2.jpg"),
        require("../../../assets/image/product4-3.jpg")
      ],
      delay: 300
    },
    {
      title: "Arriendo de Equipos",
      description: "Servicio de arriendo de equipos móviles y equipos especializados para proyectos industriales.",
      images: [
        require("../../../assets/image/product5-1.jpg"),
        require("../../../assets/image/product5-2.jpg")
      ],
      delay: 350
    },
    {
      title: "Servicios Técnicos",
      description: "Obras civiles menores, mantención de aire acondicionado para equipos de minería, servicios transversales en general y lavado de equipos móviles de minería.",
      images: [
        require("../../../assets/image/product6-1.jpg"),
        require("../../../assets/image/product6-2.jpg"),
        require("../../../assets/image/product6-3.jpg"),
      ],
      delay: 400
    }
  ]
};

export default config;