import React from 'react';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false
    };
  }

  render() {
    return (
      <section className='navbar-section-6'>
        <nav className="navbar navbar-light navbar-expand-md py-3">
          <div className="container">
            <a className="navbar-brand d-flex align-items-center" href="/">
              <img className="img-fluid logo" src={require("../../assets/image/logo-wb.png")} alt="Logo Comercial Win" />
            </a>
            <button className="navbar-toggler" data-toggle="collapse" data-target="#navcol-6">
              <span className="sr-only">Toggle navigation</span>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div id="navcol-6" className="collapse navbar-collapse">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item"><a className="nav-link" href="#about">Quiénes Somos</a></li>
                <li className="nav-item"><a className="nav-link" href="#services">Productos y Servicios</a></li>
                <li className="nav-item"><a className="nav-link" href="#contact">Contacto</a></li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    );
  }
}

export default Navbar;