import React from 'react';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import config from '../../config';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      isLoading: false,
      isSuccess: false,
      isError: false,
      isErrorName: false,
      isErrorEmail: false,
      isErrorMessage: false
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      [`isError${name.charAt(0).toUpperCase() + name.slice(1)}`]: false
    });
  };

  validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  validate() {
    const errors = {};
    errors.isErrorName = this.state.name.length < 3;
    errors.isErrorEmail = !this.validateEmail(this.state.email);
    errors.isErrorMessage = this.state.message.length < 10;
    errors.hasError = errors.isErrorName || errors.isErrorEmail || errors.isErrorMessage;
    return errors;
  }

  contact = async (data) => {
    try {
      const response = await fetch(config.api + "/contact", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      });
      return await response.json();
    } catch (error) {
      console.error("Error sending contact form:", error);
      throw error;
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const errors = this.validate();
    
    if (errors.hasError) {
      delete errors.hasError;
      this.setState({ ...errors });
      return;
    }

    this.setState({ isLoading: true, isError: false, isSuccess: false });

    try {
      const response = await this.contact({
        name: this.state.name,
        email: this.state.email,
        message: this.state.message
      });
      console.log(response);
      
      if (response && response.code === 0) {
        this.setState({
          isLoading: false,
          isError: false,
          isSuccess: true,
          name: '',
          email: '',
          message: ''
        });
      } else {
        this.setState({ isLoading: false, isError: true, isSuccess: false });
      }
    } catch (e) {
      console.error('Contact form submission error:', e);
      this.setState({ isLoading: false, isError: true, isSuccess: false });
    }
  };

  render() {
    const { isLoading, isSuccess, isError } = this.state;
    return (
      <section id='contact' className='contact-section-6'>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-8 col-xl-6 text-center mx-auto">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={100}>
                <h2 className="font-weight-bold">Contacto</h2>
                <p>Contáctenos para conocer más sobre nuestras soluciones industriales y cómo podemos ayudar a su empresa.</p>
              </ScrollAnimation>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-6 col-xl-4">
              <div className="d-flex flex-column justify-content-center align-items-start h-100">
                <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={150}>
                  <div className="d-flex align-items-center p-3">
                    <i className="fa fa-phone"></i>
                    <div className="px-2">
                      <h6 className="font-weight-bold mb-0">Teléfono</h6>
                      <p className="mb-0">+56 9 6353 0818</p>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={200}>
                  <div className="d-flex align-items-center p-3">
                    <i className="fa fa-envelope"></i>
                    <div className="px-2">
                      <h6 className="font-weight-bold mb-0">Correo</h6>
                      <p className="mb-0">ventas@comercialwin.cl</p>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={250}>
                  <div className="d-flex align-items-center p-3">
                    <i className="fa fa-map-marker"></i>
                    <div className="px-2">
                      <h6 className="font-weight-bold mb-0">Dirección</h6>
                      <p className="mb-0">San Francisco 7310, San Ramón, Santiago</p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={300}>
                <form className="p-3 p-xl-4" onSubmit={this.handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className={`form-control ${this.state.isErrorName ? 'is-invalid' : ''}`}
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                      placeholder="Nombre"
                      required
                      aria-label="Nombre"
                    />
                    {this.state.isErrorName && 
                      <div className="invalid-feedback">El nombre debe tener al menos 3 caracteres</div>
                    }
                  </div>

                  <div className="mb-3">
                    <input
                      type="email"
                      className={`form-control ${this.state.isErrorEmail ? 'is-invalid' : ''}`}
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      placeholder="Correo"
                      required
                      aria-label="Correo"
                    />
                    {this.state.isErrorEmail && 
                      <div className="invalid-feedback">Por favor ingrese un correo válido</div>
                    }
                  </div>

                  <div className="mb-3">
                    <textarea
                      className={`form-control ${this.state.isErrorMessage ? 'is-invalid' : ''}`}
                      id="message"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleInputChange}
                      rows="6"
                      placeholder="Mensaje"
                      required
                      aria-label="Mensaje"
                    ></textarea>
                    {this.state.isErrorMessage && 
                      <div className="invalid-feedback">El mensaje debe tener al menos 10 caracteres</div>
                    }
                  </div>

                  <div>
                    <button 
                      className="btn btn-primary d-block w-100" 
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? 'Enviando...' : 'Enviar'}
                    </button>
                  </div>

                  {isSuccess && (
                    <div className="alert alert-success mt-3" role="alert">
                      ¡Mensaje enviado con éxito! Nos pondremos en contacto pronto.
                    </div>
                  )}

                  {isError && (
                    <div className="alert alert-danger mt-3" role="alert">
                      Hubo un error al enviar el mensaje. Por favor intente nuevamente.
                    </div>
                  )}
                </form>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;