import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import ReactGA from 'react-ga'

import './assets/css/fonts.css';
import './assets/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/fontawesome.min.css';
import './pages/Home.css'

class App extends Component {
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return (
      <div className="App">
          <Routes />
      </div>
    );
  }
}

export default withRouter(App);
