import React from 'react';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeVideoIndex: 0
    };
    
    // Array de videos
    this.videos = [
      require("../../assets/video/video1.mp4"),
      require("../../assets/video/video2.mp4"),
      require("../../assets/video/video3.mp4"),
      require("../../assets/video/video4.mp4"),
      require("../../assets/video/video5.mp4"),
    ];
    
    // Referencias para cada elemento de video
    this.videoRefs = this.videos.map(() => React.createRef());
  }

  componentDidMount() {
    // Comenzar la reproducción del primer video
    if (this.videoRefs[0].current) {
      this.videoRefs[0].current.play().catch(error => {
        console.log("Error reproduciendo video:", error);
      });
    }

    // Agregar event listeners a todos los videos
    this.videoRefs.forEach((ref, index) => {
      if (ref.current) {
        ref.current.addEventListener('ended', () => this.handleVideoEnd(index));
      }
    });
  }

  componentWillUnmount() {
    // Limpiar event listeners
    this.videoRefs.forEach((ref, index) => {
      if (ref.current) {
        ref.current.removeEventListener('ended', () => this.handleVideoEnd(index));
      }
    });
  }

  handleVideoEnd = (index) => {
    const nextIndex = (index + 1) % this.videos.length;
    
    // Ocultar el video actual y mostrar el siguiente
    this.setState({ activeVideoIndex: nextIndex }, () => {
      // Reproducir el siguiente video
      if (this.videoRefs[nextIndex].current) {
        this.videoRefs[nextIndex].current.play().catch(error => {
          console.log("Error reproduciendo siguiente video:", error);
        });
      }
    });
  }

  render() {
    const { activeVideoIndex } = this.state;
    
    return (
      <section className="banner-section-6 text-center text-white d-flex">
        {/* Videos de fondo - cada uno en su propio contenedor */}
        {this.videos.map((videoSrc, index) => (
          <div 
            key={index}
            className="video-background"
            style={{ 
              opacity: index === activeVideoIndex ? 1 : 0,
              transition: 'opacity 1s ease-in-out',
              zIndex: -2
            }}
          >
            <video 
              ref={this.videoRefs[index]}
              muted 
              playsInline
              className="video-bg"
              preload="auto"
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
        ))}
        
        {/* Contenido del banner */}
        <div className="container my-auto position-relative">
          <div className="row">
            <div className="col-md-8 text-center text-md-left d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center mb-4">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
                <h1 className="text-uppercase font-weight-bold">Soluciones industriales para toda la cadena de valor</h1>
                <p className="my-3">Innovación, excelencia operacional y compromiso con nuestros clientes.</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;